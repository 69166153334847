import React, { useState, useEffect } from "react"
import Layout from "../components/public/layout"
import { Helmet } from "react-helmet"
import '../styles/main.css'
import axios from "axios"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"

const KalendarzIntencji = (props) => {

    const [data, setData] = useState(props.pageContext.intentionsData)
    const [seek, setSeek] = useState(false)
    const [seekData, setSeekData] = useState([])
    const currentYear = props.pageContext.currentYear
    const dataYears = props.pageContext.dataYears
    // const getData = async () => {
    //     let tempList = []
    //     for (let i = 1; i <= 53; i++) {
    //         const response = await axios.get(
    //             `${process.env.GATSBY_API_URL}:8080/api/v1/noauth/days/weekNumber/${i}`,
    //             {
    //                 headers: { "Content-Type": "application/json" },
    //                 withCredentials: true
    //             }
    //         );
    //         tempList.push(response.data)
    //         if (i === 1) {
    //             setYear(parseInt(response.data[0].date.substring(0, 4)))
    //         }
    //     }
    //     // console.log(tempList)
    //     setData(tempList)
    // }

    const getSeekData = async (type, text) => {
        const response = await axios.get(
            `${process.env.GATSBY_API_URL}:8080/api/v1/noauth/days/year/${currentYear}/query/${type}/${text}`,
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            }
        );
        // console.log(response.data)
        setSeekData(response.data)
    }

    useEffect(() => {
        let date = new Date()
        if (date.getFullYear() === currentYear) {
            document.querySelector('#jumpdatepicker').value = date.toISOString().substring(0, 10)
            jumpToDate()
        }
        const handleScroll = e => {
            e.preventDefault()
            let up = document.querySelector('#up-button')
            if (window.scrollY > 100)
                up.style.opacity = 1
            else
                up.style.opacity = 0
        }
        window.addEventListener('scroll', handleScroll)


        // getData().then(jumpToDate)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const formatTime = (time) => {
        return time.substring(0, time.length - 3)
    }


    const formatDate = (date, nameOfDay) => {
        let tempDate = new Date(date)
        return nameOfDay !== null ? nameOfDay + ', ' + tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'numeric', year: 'numeric' })
            : tempDate.toLocaleDateString('pl-PL', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' })
    }

    const formatDateWeek = (date) => {
        let tempDate = new Date(date)
        return tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'long' })
    }
    const formatTitle = (title, category) => {
        switch (category) {
            case 'NIEDZIELA':
            case 'UROCZYSTOSC_BEZ_MSZY_WIECZORNEJ':
            case 'UROCZYSTOSC_JAK_NIEDZIELA':
            case 'WIELKANOC':
            case 'BOZE_CIALO':
            case 'BOZE_NARODZENIE':
                return <span style={{ color: 'red' }}>{title}</span>
            default:
                return <span>{title}</span>
        }
    }
    const jumpToDate = () => {
        let date = document.querySelector('#jumpdatepicker').value
        let dateDiv = document.getElementById(date)
        if (dateDiv == null)
            return
        let top = dateDiv.offsetTop
        window.scrollTo(0, top - 90)
    }

    const seekIntentions = () => {
        let type = document.querySelector('#query-type').value
        let text = document.querySelector('#query-text').value
        getSeekData(type, text)
        setSeek(true)
    }

    const showAll = () => {
        setSeek(false)
    }
    return (
        <Layout>
            <Helmet title={`Intencje na rok ${currentYear} | Parafia w Starych Skoszewach`} />
            <div className="to-up">
                <button id="up-button" onClick={(() => window.scrollTo(0, 0))}>
                    <FontAwesomeIcon icon={faChevronUp} />
                </button>
            </div>
            <div className="container" style={{ flexDirection: 'column', minHeight: '60vh' }}>
                {/* <h3 style={{textAlign: 'center', fontSize: 20}}>Intencje z innych lat:</h3> */}
                <div className="other-years">
                    {
                        dataYears.filter(year => year !== currentYear).map((year) => {
                            return <Link to={`/kalendarz-intencji/${year}`}><button>Intencje na rok {year}</button></Link>
                        })
                    }
                </div>
                <h1 style={{ textAlign: 'center' }}>Intencje na rok {currentYear}</h1>
                <div className="datepicker-container">
                    <input type="date"
                        id="jumpdatepicker"
                        min={`${currentYear}-01-01`}
                        max={`${currentYear}-12-31`}
                    >
                    </input>
                    <button onClick={jumpToDate}>
                        IDŹ DO DATY
                    </button>
                </div>
                <div className="query-container">
                    <div>Rodzaj intencji: </div>
                    <select name="query-type" id="query-type" style={{ width: 100 }}>
                        <option value="null">-</option>
                        <option value="+">Za zm.</option>
                        <option value="dz-bł">Dz-bł.</option>
                    </select>
                    <div>Szukaj intencji: </div>
                    <input type="text"
                        id="query-text"
                        placeholder="Szukaj intencji"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                seekIntentions()
                            }
                        }}
                    >
                    </input>
                    <div className="buttons">
                        <button onClick={seekIntentions}>
                            SZUKAJ
                        </button>
                        {
                            seek ?
                                <button id='search-button' onClick={showAll} style={{ marginLeft: 10 }}>
                                    POKAŻ WSZYSTKIE
                                </button>
                                : null
                        }
                    </div>
                </div>
                {!seek ?
                    data.length === 0 ? <div style={{ textAlign: 'center', marginTop: 20 }}>Trwa ładowanie intencji ...</div> :
                        data.map(week => {
                            week = week.sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                            return (
                                <div className="intentions-container">
                                    <h3>Od {formatDateWeek(week[0].date)} do {formatDateWeek(week[week.length - 1].date)} {currentYear}:</h3>
                                    {
                                        week.map(day => {
                                            return (
                                                <div style={{ background: 'transparent', margin: '10px 0' }} id={`${day.date}`}>
                                                    <strong style={{ textTransform: 'capitalize' }}>{formatTitle(formatDate(day.date, day.nameOfDay) + ',', day.category)}</strong>
                                                    {
                                                        day.masses.map(mass => {
                                                            return (
                                                                <div className="mass">
                                                                    <div className="mass-time">g. {formatTime(mass.time)}</div>
                                                                    <div className="mass-intention">{mass.intention}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    :
                    <div className="intentions-container">
                        <h3>Wyniki wyszukiwania:</h3>
                        {
                            seekData.map(day => {
                                return (
                                    <div style={{ background: 'transparent', margin: '10px 0' }} id={`${day.date}`}>
                                        <strong style={{ textTransform: 'capitalize' }}>{formatTitle(formatDate(day.date, day.nameOfDay) + ',', day.category)}</strong>
                                        {
                                            day.masses.map(mass => {
                                                return (
                                                    <div className="mass">
                                                        <div className="mass-time">g. {formatTime(mass.time)}</div>
                                                        <div className="mass-intention">{mass.intention}</div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>


                }
                <div className="other-years">
                    {
                        dataYears.filter(year => year !== currentYear).map((year) => {
                            return <Link to={`/kalendarz-intencji/${year}`}><button>Intencje na rok {year}</button></Link>
                        })
                    }
                </div>
            </div>
        </Layout>
    )
}

export default KalendarzIntencji